import React from "react";
import "./App.scss";

function App() {
  return (
    <div id="content">
      <div className="row">
        <header>
          <p>Hi! I'm Mat. 👋</p>
        </header>
        <div className="details">
          <p>
            I'm a Software Engineer, specialising in Web Apps and
            infrastructure.
          </p>
          <p>
            I feel at home on both ends of the stack. I focus on the problems
            I'm solving rather than the front-end / server-side division.
          </p>
          <p>
            I treat programming languages, libraries and frameworks as tools
            that can be used to deliver a solution, not as a definition of what
            I do. I believe that methodology is more important and that a good
            understanding of the basics enables me to adapt quickly (especially
            if a project is well-maintained).
          </p>
          <p>
            Outside of full-time work, I'm a recent first-class graduate, with a
            mobile application as my final project.
          </p>
          <p>
            I enjoy hiking, camping, cycling, mountain biking, a bit of DIY and
            woodworking.
          </p>
        </div>
      </div>
      <div className="row">
        <header>Tech:</header>
        <div className="details">
          <p>
            Recently, I've been developing internal tools for several companies,
            including GIS tools, bespoke React components, web portals written
            in Vue.js as well as backend services in forms of Node APIs and
            event-driven systems.
          </p>
          <p>
            I was responsible for the documentation, infrastructure on which
            they run, pipelines and cloud deployments.
          </p>
          <p>
            In the past I've used Angular and React to develop frontends for a
            wide range of products, including geospatial intelligence systems,
            internet booking engines, in-house inventory management systems and
            retail platforms.
          </p>
          <p>
            I'm decent with SQL and document databases, shell scripting, a few
            testing libraries and build tools. I'm also quick to pick up new
            tools and languages if the project calls for it.
          </p>
        </div>
      </div>
      <div className="row">
        <header>Motivations:</header>
        <div className="details">
          <p>
            Interesting problems and good company culture are the main selling
            points for me.
          </p>
          <p>
            I enjoy working with people who are curious and open minded, willing
            to learn as well as share knowledge with others, regardless of their
            experience level.
          </p>
          <p>
            I prefer to work on projects that encourage dialogue and
            collaboration.
          </p>
          <p>
            Given the opportunity to understand the 'why' behind a project, I
            can apply my expertise to meet the stakeholders' needs and suggest
            the best solutions.
          </p>
          <p>
            I appreciate well thought out architectures, CI/CD pipelines, and
            good test coverage.
          </p>
        </div>
      </div>
      <div className="row">
        <header>Interested?</header>
        <div className="details">
          <p>
            I'm based in the beautiful village of Roslin (just outside of
            Edinburgh) and I'm open to remote or hybrid roles.
          </p>
          <p>
            If you'd like to get in touch, you can{" "}
            <a href="mailto:mat@fluffware.co.uk" className="contact">
              drop me an email
            </a>{" "}
            or message me on{" "}
            <a
              href="https://www.linkedin.com/in/mateusz-sapielak-504602123"
              className="contact"
            >
              LinkedIn
            </a>
            .
          </p>
        </div>
      </div>
      <div className="footer">
        <p>Favicon made by Freepik from www.flaticon.com</p>
      </div>
    </div>
  );
}

export default App;
